import React from 'react';
import './style.scss';
import { graphql, StaticQuery } from 'gatsby';
import FeatureImage from "../../FeaturedImage/FeaturedImage"


 


const featImageBlock = ({props}) => {

  /*   */

    return(
        <React.Fragment >
       
            <StaticQuery query={graphql`
                {
                    allWordpressPage(filter: {template: {eq: "home-custom-template.php"}}) {
                        edges {
                            node {
                                id
                                slug
                                status
                                title
                                content
                                template
                                acf {
                                    homepage_main_heading
                                    homepage_sub_heading
                                    desktop_featured_image {
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 1400) {
                                                    # sizes
                                                     src
                                                    # srcSet
                                                    # tracedSVG
                                                    # aspectRatio
                                                    ...GatsbyImageSharpFluid 
                                                }
                                            }
                                        }
                                    }
                                }
                                featured_media {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 750) {
                                                sizes
                                                src
                                                srcSet
                                                # tracedSVG
                                                aspectRatio
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                  
            `
            } render={(props) => {
                //console.log('props Feat Image ',props);
                // let deskImg = <Img fluid={props.allWordpressPage.edges[0].node.acf.desktop_featured_image.localFile.childImageSharp.fluid} />;
                // let mobImg  = <Img fluid={props.allWordpressPage.edges[0].node.featured_media.localFile.childImageSharp.fluid} />;

                return(
                  
                    
                    <div className="blockwrap menu-spacer ft-img-blk" >
                    {/* <div className="blockwrap menu-spacer ft-img-blk" style={{backgroundImage: "url(" + props.allWordpressPage.edges[0].node.acf.desktop_featured_image.localFile.childImageSharp.original.src + ")"}}> */}

                        <div className="heading-wrap">
                            <div className="header-copy">
                                <div className="inner">
                                    <h1 className="animate-underline">{props.allWordpressPage.edges[0].node.acf.homepage_main_heading}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="feature-img">
                            <FeatureImage pageContext={props.allWordpressPage.edges[0].node} /> 
                        </div>

                       
                        <div className="sub-heading-wrap">
                            <h2>{props.allWordpressPage.edges[0].node.acf.homepage_sub_heading}</h2>
                        </div>

                    </div>
                       
                    
                    
                  
        
            )}} />
       
        </React.Fragment>
        
    )

}
export default featImageBlock;