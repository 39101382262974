import React from "react"
import Image from "gatsby-image/withIEPolyfill";
import { isDesktop } from "../../helpers";
import "./style.scss";

export default ({pageContext, mobile, desktop}) => {
    if(typeof pageContext.acf.desktop_featured_image === "undefined" || pageContext.acf.desktop_featured_image === null)
        return null;

    try {
        return <Image    
            fluid={isDesktop() ?
                (desktop || pageContext.acf.desktop_featured_image.localFile.childImageSharp.fluid) :
                (mobile || pageContext.featured_media.localFile.childImageSharp.fluid)
            }
            imgStyle={{objectPosition: "center"}}
            className="Featured-Image"
        />;
    } catch(err) {
        console.log("Feature image exception", err);
        return null;
    }
}