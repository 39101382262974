import React from 'react';
import './style.scss';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from "gatsby-image"

const featPagesBlock = ({props}) => {

      return(
          
          <div className="blockwrap">
              <StaticQuery query={graphql`
                   {
                    homePage: allWordpressPage(filter: {template: {eq: "home-custom-template.php"}}) {
                      edges {
                        node {
                          id
                          wordpress_id
                          date
                          slug
                          title
                          excerpt
                          content
                          template
                          acf {
                            meta_title
                            meta_description
                            feat_pages {
                              page_title
                              page_description
                              link
                              featured_image {
                                localFile {
                                  childImageSharp {
                                    fluid(maxWidth: 250) {
                                      sizes
                                      src
                                      srcSet
                                      aspectRatio
                                    }
                                  }
                                }
                              }
                            }
                            desktop_featured_image {
                              localFile {
                                childImageSharp {
                                  fluid(maxWidth: 1400) {
                                    sizes
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                }
                              }
                            }
                          }
                          featured_media {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 750) {
                                  sizes
                                  src
                                  srcSet
                                  aspectRatio
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
              `
              } render={(props) => {
                
                    console.log('props hp',props.homePage.edges);
                    //let deskImg = <Img fluid={props.allWordpressPage.edges[0].node.acf.desktop_featured_image.localFile.childImageSharp.fluid} />;
                    //let mobImg  = <Img fluid={props.allWordpressPage.edges[0].node.featured_media.localFile.childImageSharp.fluid} />;
                    const featuredPages = props.homePage.edges[0].node.acf.feat_pages.map((featPage, index )=> {
                      /* const sources = [
                        featPage.node.featured_media.mobile_crop.childImageSharp.fluid,
                        //data.mobileImage.childImageSharp.fluid,
                        {
                          ...featPage.node.featured_media.desktop_crop.childImageSharp.fluid,
                          //...data.desktopImage.childImageSharp.fluid,
                          media: `(min-width: 768px)`,
                        },
                      ] */
                        //let page = featPage.node;
                        return (

                          <li key={index}>
                            <Link to={`/${featPage.link}`} className="link-feat-page ">

                                    <div className="feat-img-wrap">
                                        <Img fluid={featPage.featured_image.localFile.childImageSharp.fluid} className="feat-img"   />
                                    </div>
                                    <div className="info">
                                        <div className="inner">
                                          <h3><span>{featPage.page_title}</span></h3>
                                          <p>{featPage.page_description}</p>
                                        
                                          {/*   <Link to={`/engage${page.path}`} className="link-feat-page btn-basic blue">Learn more</Link> */}
                                          {/* <Link to={`/${featPage.link}`} className="link-feat-page btn-basic blue">Learn more <i className="fa fa-long-arrow-right"></i></Link> */}
                                          <span className="link">Learn more <i className="fa fa-long-arrow-right"></i></span>
                                        </div>
                                    </div>
                            </Link>
                          </li>
                                
                            )
                    })
                
                    return(
                      
                        <div className="feat-page-wrap ">

                            <ul className="feat-page-list container">
                                {featuredPages} 
                            </ul>
                            
                        </div>
                        
                    )

              }} />
          </div>
          
      )
  
  }
  export default featPagesBlock;