import React from 'react';
import './style.scss';
import { graphql, StaticQuery, Link } from 'gatsby';
import podcastImg from '../../../assets/images/podcast-ft-home.jpg';

const featPodcastBlock = (props) => {

    return(

        <div className="blockwrap">
       
             <StaticQuery query={graphql`
                  {
                    allWordpressWpPodcast {
                      edges {
                        node {
                          id
                          wordpress_id
                          date
                          slug
                          title
                          excerpt
                          content
                          template
                          link
                          acf {
                            podcast_url
                            episode_number
                            desktop_featured_image {
                              localFile {
                                childImageSharp {
                                  fluid(maxWidth: 1400) {
                                    sizes
                                    src
                                    srcSet
                                    tracedSVG
                                    aspectRatio
                                  }
                                }
                              }
                            }
                          }
                          featured_media {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 1400) {
                                  sizes
                                  src
                                  srcSet
                                  tracedSVG
                                  aspectRatio
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
              `
              } render={(props) => {
                    
                    return(
                      
                        <div className="feat-podcast-wrap">
                            <div className="container">
                                <span className="h2-wrap"><h2>Podcast</h2></span>
                                <img src={podcastImg} className="podcast-ft-img"  alt="feature podcasts page" />
                                <div className="info" >                  
                                  <h3>
                                      Episode {props.allWordpressWpPodcast.edges[0].node.acf.episode_number}<br />
                                      {props.allWordpressWpPodcast.edges[0].node.title}
                                  </h3>
                                  <div dangerouslySetInnerHTML={{__html: props.allWordpressWpPodcast.edges[0].node.excerpt}}></div>
                                  <Link to={`/resources/podcasts`} className="btn-basic">Listen Now <i className="fa fa-long-arrow-right"></i></Link> 
                                </div>      
                            </div>
                            
                        </div>
                        
                    )

              }} />
        </div>

    )

}
export default featPodcastBlock;