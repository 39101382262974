import React from "react"
import Wrapper from "../templates/Wrapper/Wrapper"
import SEO from "../components/seo"
import FeatImageBlock from '../components/HomePage/FeatImageBlock/FeatImageBlock';
import FeatPagesBlock from '../components/HomePage/FeatPagesBlock/FeatPagesBlock';
import FeatPodcastBlock from '../components/HomePage/FeatPodcastBlock/FeatPodcastBlock';
import FeatBlogPostsBlock from '../components/HomePage/FeatBlogPostsBlock/FeatBlogPostsBlock';

const IndexPage = () => (
        <React.Fragment>
            <Wrapper footerPattern={false}>
                <SEO title="Home" />
                <FeatImageBlock />
                <FeatPagesBlock />
                <FeatPodcastBlock />
                <FeatBlogPostsBlock />
            </Wrapper>
        </React.Fragment>
    
)

export default IndexPage