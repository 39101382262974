import React from 'react';
import './style.scss';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from "gatsby-image";
import circleMask from '../../../assets/images/circle-mask.png';
import {htmlDecode} from '../../../helpers'

const featPostsBlock = ({props}) => {

  /*   */

    return(
        
        <div className="blockwrap menu-spacer feat-posts-block">
          <div className="container">
            <StaticQuery query={graphql`
                {
                  allWordpressWpCommunityBlog(limit: 3) {
                    edges {
                      node {
                        id
                        wordpress_id
                        date(formatString: "MMMM Do YYYY")
                        slug
                        title
                        excerpt
                        author {
                          name
                        }
                        content
                        template
                        link
                        path
                        acf {
                          desktop_featured_image {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 1400) {
                                  sizes
                                  src
                                  srcSet
                                  tracedSVG
                                  aspectRatio
                                }
                              }
                            }
                          }
                        }
                        featured_media {
                          mobile_ft: localFile {
                            childImageSharp {
                              fluid(maxWidth: 750) {
                                sizes
                                src
                                srcSet
                                aspectRatio
                              }
                            }
                          }
                          mobile_crop: localFile {
                            childImageSharp {
                              fluid(maxHeight: 350, maxWidth: 350, cropFocus: CENTER) {
                                aspectRatio
                                src
                                sizes
                                presentationWidth
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } 
            `
            } render={(props) => {
                //console.log('props ',props.allWordpressWpCommunityBlog.edges);
                //let deskImg = <Img fluid={props.allWordpressWpCommunityBlog.edges[0].node.acf.desktop_featured_image.localFile.childImageSharp.fluid} />;
                //let mobImg  = <Img fluid={props.allWordpressWpCommunityBlog.edges[0].node.featured_media.localFile.childImageSharp.fluid} />;
                const featuredPages = props.allWordpressWpCommunityBlog.edges.map(featPage => {
                    let page = featPage.node;
                    return (
                            <li key={page.wordpress_id}>
                                 {/* <div className="feat-img-wrap">
                                    <Img fluid={page.featured_media.localFile.childImageSharp.fluid} className="feat-img clip-circle" alt="feat post image" />
                                </div>   */}
                                 <div className="ft-img-wrap">
                                    <img src={circleMask} className="circle-mask" alt="circle masking out" />
                                    <Img fluid={page.featured_media.mobile_crop.childImageSharp.fluid} className="clip-circle" alt="Featured Post" />
                                </div> 
                                <div className="info">
                                    <h3><Link to={`/resources/${page.path}`} className="">{htmlDecode(page.title)}</Link></h3>
                                    <h4>{page.author.name}, {page.date}</h4>
                                    <div dangerouslySetInnerHTML={{__html: page.excerpt}} />
                                   {/*  <p className="excerpt">{page.excerpt}</p> */}
                                    <Link to={`/resources/${page.path}`} className="link-read-more">Read more</Link>
                                </div>
                            </li>
                        )
                })
            
                return(
                  
                    <div className="feat-blog-posts-wrap">
                        <h2>Latest Blog Posts</h2>
                        <ul className="feat-blog-posts-list">
                            {featuredPages}
                        </ul>

                        <Link to="/resources/community-blog" className="all-posts btn-basic">See all blog posts <i className="fa fa-long-arrow-right"></i></Link>
                        
                    </div>
                    
                )

          }} />
          </div>
        </div>
        
    )

}
export default featPostsBlock;